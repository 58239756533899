import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../templates/layout"
import SEO from "../components/seo"
import Stats from "../components/stats"
import Footer from "../components/footer"
import ScrollAnimation from "react-animate-on-scroll"
import GatsbyImage from "gatsby-image"

const AboutUs = () => {
  const data = useStaticQuery(
    graphql`
      query {
        our_mission: file(relativePath: { eq: "our-mission.webp" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        career: file(relativePath: { eq: "career.webp" }) {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Layout isHeaderFixed={true}>
      <SEO
        title="About"
        description="Get fast and reliable solution for your digital problem, We got you covered with all software from Website development to ECommerce site to Mobile app to AI enabled drones."
      />
      <div className="pt-24 pb-8 lg:pb-28 lg:pt-28 mx-4">
        <div className="text-center text-primary-600 mt-16 lg:mb-12 mb-8 font-medium">
          OUR PURPOSE
        </div>
        <div className="text-center font-extrabold text-black text-5xl mb-4 font-display">
          Bringing future to present.
        </div>
        <div className="text-center text-xl">
          So that everyone can make their life easier with technologies from
          future.
        </div>
      </div>

      <div className="relative overflow-hidden max-w-screen-xl lg:mx-auto mx-4">
        <div className="flex flex-wrap ">
          <div className="w-full lg:w-1/2">
            <main className="py-8 lg:py-8 h-full">
              <div className="text-left">
                <div className="text-primary-600 mb-8 mt-4 font-medium">
                  OUR MISSION
                </div>
                <h2 className="text-3xl tracking-tight leading-10 font-extrabold text-black sm:text-4xl sm:leading-none md:text-5xl">
                  Who we are
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  We are technologists, designers, marketers, and educators but
                  first and foremost, we are friends and colleagues. Our mission
                  is to help make people’s lives happier, more successful and
                  enjoyable by making their businesses run faster and easier. We
                  believe in giving everyone the opportunity and power to focus
                  on what they love to do. Bringing teams, partners and
                  customers closer together in the process.
                </p>
              </div>
            </main>
          </div>
          <div className="w-full lg:w-1/2">
            <GatsbyImage
              className="w-full object-cover h-full"
              fluid={data.our_mission.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="my-16 lg:my-28">
          <div className="text-center font-extrabold text-black text-5xl mb-8 font-display">
            Story behind Annwn Tech
          </div>
          <div className="text-center text-xl">
            Annwn Tech Private Limited (formaly Annwn) founded with seeing the
            lack of reliable solution in technology world. Most of the people
            only see future technology in Sci-Fi Movies and in their dreams.
            With same dream two of it's founder Mr. Manish Kumar Pandit (CTO)
            and Mr. Arnav Singh Raizada started it and then it become no more
            dream but a goal with whole lot of opportunity. Every step of Annwn
            is building path to future of technologies. At Annwn we believe that
            everything you dream of is possible it only need proper technology
            and enough dedication. You give your dedication and we give our
            technologies and let's see impossible being possible.
          </div>
        </div>
      </div>
      <div className="max-w-screen-2xl lg:mx-auto mx-4 rounded bg-yellow-100">
        <Stats />
      </div>
      <div class="container mx-auto w-full h-full mb-8">
        <div class="relative wrap overflow-hidden p-4 md:p-10 h-full">
          <div class="absolute border-opacity-20 border-gray-700 h-full border md:left-1/2 hidden md:block"></div>

          <div class="mb-8 flex justify-between items-center w-full right-timeline">
            <div class="order-1 md:w-5/12 hidden md:block"></div>
            <div class="z-20 md:flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full hidden">
              <h1 class="mx-auto font-semibold text-lg text-white">1</h1>
            </div>
            <div class="order-1 bg-gray-400 shadow-xl md:w-5/12 px-6 py-4">
              <h3 class="mb-3 font-bold text-gray-800 text-xl">2019</h3>
              <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
                Annwn Tech Private limited founded in 2019 by two super
                enthusiastic person with common and great vision for humanity.
              </p>
            </div>
          </div>

          <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
            <div class="order-1 md:w-5/12 hidden md:block"></div>
            <div class="z-20 md:flex items-center order-1 bg-gray-800 shadow-xl w-8 h-8 rounded-full hidden">
              <h1 class="mx-auto text-white font-semibold text-lg">2</h1>
            </div>
            <div class="order-1 bg-red-400 shadow-xl md:w-5/12 px-6 py-4">
              <h3 class="mb-3 font-bold text-white text-xl">2020</h3>
              <p class="text-sm font-medium leading-snug tracking-wide text-white text-opacity-100">
                In short span of time (only 1 year) Annwntech Private Limited
                expanded their business in across 6 cities with hundreds of
                happy customer. We started delivering Physical Product along
                with software solution. We helped our customer in difficult time
                of global panedemic of COVID-19 with our End-to-End secure video
                conferencing Platform Pratibimb which brought many families
                together.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-primary-600 text-white rounded mx-4 md:mx-8 ">
        <div className="max-w-screen-2xl lg:mx-auto mx-2">
          <div className="flex flex-wrap flex-col-reverse md:flex-row justify-between">
            <div className="w-full md:w-1/2 m-auto p-4">
              <div className="text-xl font-bold mb-4">OUR VALUES</div>
              <div className="text-3xl leading-10 font-extrabold sm:text-4xl sm:leading-none md:text-5xl mb-4">
                Shared values keep us connected & guide us as one team
              </div>
              <p class="text-lg font-medium leading-snug tracking-wide text-white text-opacity-90">
                We’re building a different type of company. One that’s focused
                on the growth and happiness of our community, our customers and
                our employees. Life is a journey and we’ll be with you, helping
                you get there, one step at a time. These values collectively
                guide our decisions and actions.
              </p>
            </div>
            <div className="w-full md:w-2/5 overflow-hidden">
              <ScrollAnimation
                className="flex flex-wrap cards max-w-md"
                animateIn="fadeInUp"
                animateOnce="true"
              >
                <div class="card__item card__item--dummy">
                  <div class="card__thumb"></div>
                </div>
                <div class="card__item card__item--dummy">
                  <div class="card__thumb"></div>
                </div>
                <div class="card__item">
                  <div class="flex items-center justify-center h-8 w-8 rounded-full bg-yellow-300 text-white mb-4">
                    {/* <!-- Heroicon name: globe-alt --> */}
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M19.504 12.65L12.008 21l-7.496-8.35a5.707 5.707 0 0 1-1.144-1.911 6.245 6.245 0 0 1-.366-2.266 6.204 6.204 0 0 1 .472-2.241 5.612 5.612 0 0 1 1.232-1.84 4.88 4.88 0 0 1 1.784-1.13 4.473 4.473 0 0 1 2.037-.23 4.62 4.62 0 0 1 1.949.708c.6.385 1.12.905 1.532 1.528a5.234 5.234 0 0 1 1.535-1.515 4.617 4.617 0 0 1 1.945-.698c.684-.08 1.375 0 2.03.235.656.234 1.26.619 1.778 1.128a5.61 5.61 0 0 1 1.228 1.835 6.249 6.249 0 0 1 .114 4.494 5.713 5.713 0 0 1-1.134 1.91"></path>
                    </svg>
                  </div>

                  <div class="card__description">
                    Build with love, deliver with care.
                  </div>
                </div>
                <div class="card__item">
                  <div class="flex items-center justify-center h-8 w-8 rounded-full bg-blue-300 text-white mb-4">
                    {/* <!-- Heroicon name: globe-alt --> */}
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="12" cy="12" r="9"></circle>
                      <path d="M8 11.5l3 3 6-6"></path>
                    </svg>
                  </div>
                  <div class="card__description">
                    Invent, share, learn, innovate, iterate.
                  </div>
                </div>
                <div class="card__item">
                  <div class="flex items-center justify-center h-8 w-8 rounded-full bg-red-300 text-white mb-4">
                    {/* <!-- Heroicon name: globe-alt --> */}
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12.007 18.254L5.827 21.5l1.18-6.876L2 9.756l6.91-1L12 2.5l3.09 6.255 6.91 1-5.007 4.87 1.18 6.875-6.166-3.246z"></path>
                    </svg>
                  </div>
                  <div class="card-title"></div>
                  <div class="card__description">
                    The team is the heart. The customer is the brain.
                  </div>
                </div>
                <div class="card__item">
                  <div class="flex items-center justify-center h-8 w-8 rounded-full bg-yellow-600 text-white mb-4">
                    {/* <!-- Heroicon name: globe-alt --> */}
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M12 8v5h4"></path>
                      <circle cx="12" cy="12" r="9"></circle>
                    </svg>
                  </div>
                  <div class="card__description">
                    Patience and focus on the long-term.
                  </div>
                </div>
                <div class="card__item card__item--dummy">
                  <div class="card__thumb"></div>
                </div>
                <div class="card__item card__item--dummy">
                  <div class="card__thumb"></div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden max-w-screen-xl lg:mx-auto mx-4 py-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-1/2">
            <GatsbyImage
              className="w-full object-cover h-full"
              fluid={data.career.childImageSharp.fluid}
            />
          </div>
          <div className="w-full lg:w-2/5">
            <main className="py-8 lg:py-8 h-full">
              <div className="text-left">
                <div className="text-primary-600 my-4 font-medium">CAREERS</div>
                <h2 className="text-3xl tracking-tight leading-10 font-extrabold text-black sm:text-4xl sm:leading-none md:text-5xl">
                  Join the team that collaborates
                </h2>
                <p className="my-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  We’ve built a market-leading product that’s making work easier
                  for thousands of teams around the world.
                </p>
                <Link to="/careers">
                  <div className="py-3 px-4 bg-yellow-500 cursor-pointer text-white text-center rounded w-1/2 md:w-1/3">
                    Join our team
                  </div>
                </Link>
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default AboutUs
